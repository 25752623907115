import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect } from 'react';

import { overlayVariants } from '@/components/ui/modals/motion-variants';

import styles from './CustomDrawer.module.scss';
import Navigation from './navigation/Navigation';

type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const drawerVariant = {
  initial: {
    x: '-100%',
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
  animate: {
    x: 0,
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
  exit: {
    x: '-100%',
    transition: {
      type: 'spring',
      damping: 40,
      stiffness: 400,
    },
  },
};

const CustomDrawer: FC<DrawerProps> = ({ onClose, isOpen }) => {
  useEffect(() => {
    isOpen ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
  }, [isOpen]);

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <div className={styles.wrapper} key="modal">
          <div className={styles.innerWrapper}>
            <motion.div
              className={clsx(styles.content)}
              variants={drawerVariant}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <Navigation className={styles.body} loading={false} onClose={onClose} />
            </motion.div>

            <motion.div
              className={clsx(styles.overlay)}
              variants={overlayVariants}
              initial="hidden"
              animate="animate"
              exit="exit"
              onClick={onClose}
            />
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default CustomDrawer;
