import clsx from 'clsx';
import Link from 'next/link';
import { HTMLAttributes } from 'react';

import styles from '@/components/layout/header/custom-drawer/Submenu/Submenu.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  data: Array<{ name: string; href: string }>;
  onClose: () => void;
}

const ITEMS_IN_GROUP = 20;
export const Submenu = ({ data, onClose, className, ...rest }: Props) => {
  if (!data.length) return null;

  const groupedData = [];
  for (let i = 0; i < data.length; i += ITEMS_IN_GROUP) {
    groupedData.push(data.slice(i, i + ITEMS_IN_GROUP));
  }

  return (
    <div className={clsx(styles.sections, className)} {...rest}>
      {groupedData.map((group, index) => (
        <ol key={index} className={styles.list}>
          {group.map(({ href, name }, itemIndex) => (
            <li key={itemIndex} className={styles.link}>
              <Link href={href} onClick={onClose}>
                <span>
                  <b>{index * ITEMS_IN_GROUP + itemIndex + 1}.</b> {name}
                </span>
              </Link>
            </li>
          ))}
        </ol>
      ))}
    </div>
  );
};
