import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { HTMLAttributes, useState } from 'react';

import { Image } from '@/components/image';
import { Submenu } from '@/components/layout/header/custom-drawer/Submenu/Submenu';
import { Skeleton } from '@/components/ui/loaders/Skeletons/Skeletons';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { useGetCatalogueMenuQuery } from '@/store/api/catalogue.api';
import { switchModal } from '@/store/slices/individual-order-modal/individualOrderModal.slice';

import styles from './Navigation.module.scss';

const IndividualOrderPopupModal = dynamic(
  () => import('@/components/individual-order/IndividualOrderPopupModal')
);

const ChevronIcon = () => {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.72326 6.00005L0.861328 1.13812L1.80414 0.195312L7.60888 6.00005L1.80414 11.8048L0.861328 10.862L5.72326 6.00005Z"></path>
    </svg>
  );
};

type Props = HTMLAttributes<HTMLDivElement> & {
  onClose: () => void;
  loading: boolean;
};
const Navigation = ({ onClose, className }: Props) => {
  const { data: catalogue, isLoading } = useGetCatalogueMenuQuery();

  const [activeIndividual, setActiveIndividual] = useState(false);
  const [activeMenu, setActiveMenu] = useState<any[]>([]);

  const { isOpen } = useTypedSelector((state) => state.individualOrderModal);

  const dispatch = useAppDispatch();

  function mouseLeaveHandler() {
    setActiveMenu([]);
  }

  return (
    <motion.div className={clsx(styles.wrapper, className)}>
      <AnimatePresence initial={false}>
        <div className={styles.navigation} onMouseLeave={mouseLeaveHandler}>
          <div>
            <h2 className={styles.heading}>Каталог:</h2>
            <ul className={styles.firstLevelMenu}>
              {isLoading
                ? [...new Array(5)].map((_, index) => (
                    <li key={index}>
                      <Skeleton className={styles.skeleton} />
                    </li>
                  ))
                : catalogue?.menu.map(({ title, href, submenu, icon }) => {
                    const withSubmenu = !!submenu?.length;
                    const Component = href ? (
                      <Link href={href} onClick={onClose}>
                        {title}
                      </Link>
                    ) : (
                      <span>{title}</span>
                    );

                    return (
                      <li
                        key={title}
                        className={clsx(styles.liItem, !withSubmenu && styles.withoutSubmenu)}
                        onMouseEnter={() => setActiveMenu(submenu)}
                      >
                        <Image
                          src={`/static/icons/header/${icon}.png`}
                          width={24}
                          height={24}
                          alt={title}
                        />
                        {Component}
                        {withSubmenu && <ChevronIcon />}
                      </li>
                    );
                  })}

              <li onClick={() => setActiveIndividual(true)}>
                <a className={styles.by_photo} onClick={() => dispatch(switchModal(!isOpen))}>
                  <Image
                    src={`/static/icons/header/folder-photo.svg`}
                    width={16}
                    height={16}
                    alt="Букет по фото"
                  />
                  <span>Букет по фото</span>
                </a>
              </li>

              <li className={styles.all}>
                <Link
                  href="/catalog/bukety"
                  className={clsx(styles.link, styles.link_all)}
                  onClick={onClose}
                >
                  <span>Весь каталог</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className={clsx(styles.secondLevelMenu, activeMenu.length > 0 && styles.active)}>
            <Submenu data={activeMenu} onClose={onClose} />
          </div>
        </div>
      </AnimatePresence>

      {activeIndividual && <IndividualOrderPopupModal />}
    </motion.div>
  );
};

export default Navigation;
